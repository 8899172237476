import { extendTheme, theme } from '@chakra-ui/react';

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: 'Inter, sans-serif',
    heading: 'Ubuntu, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    ...theme.colors,
    brand: {
      100: '#E1F9F6',
      200: '#C5F3F1',
      300: '#9EDADC',
      400: '#78B3BA',
      500: '#4A808C',
      600: '#366778',
      700: '#254F64',
      800: '#173951',
      900: '#0E2943',
    },
  },
};

export default extendTheme(customTheme);
