let localhost, localPayments, localWebApp;
if (process.env.NODE_ENV === 'development') {
  localhost = `http://localhost:4000`;
  localPayments = `http://localhost:3000`;
  localWebApp = `http://localhost:3002/`;
}

const ENV = {
  dev: {
    apiUrl: 'http://localhost:4000',
    paymentsUrl: 'https://df-payments-staging.netlify.com/',
    webAppUrl: 'https://dropfriends-app-staging.netlify.app/',

    appSchema: '/',

    dOSpacesAccess: 'HKEMEMI3L75QINQFCHNV',
    dOSpacesSecret: 'XkdEvxb2il3zkdF0/fbyctDBlP5CjOwwnbI2UOfRi4Q',
    dOSpacesBucketName: 'df-test-space',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',

    cloudinary: '375424767218447',
    cloudinarySecret: 'O_lOCnkiFrBulXdmaLb3KLR5gpA',

    hereId: 'eRuKlyokJxvKA5vJUkcv',
    hereCode: 'yESlb_MreW4z1Vwnwqhafg',
  },
  staging: {
    apiUrl: 'https://api.dev.dropfriends.com',
    paymentsUrl: 'https://df-payments-staging.netlify.com/',
    webAppUrl: 'https://dropfriends-app-staging.netlify.app/',

    appSchema: 'dropfriends-staging://',

    dOSpacesAccess: 'HKEMEMI3L75QINQFCHNV',
    dOSpacesSecret: 'XkdEvxb2il3zkdF0/fbyctDBlP5CjOwwnbI2UOfRi4Q',
    dOSpacesBucketName: 'df-test-space',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',

    cloudinary: '375424767218447',
    cloudinarySecret: 'O_lOCnkiFrBulXdmaLb3KLR5gpA',

    hereId: 'eRuKlyokJxvKA5vJUkcv',
    hereCode: 'yESlb_MreW4z1Vwnwqhafg',
  },
  prod: {
    apiUrl: 'https://api.dropfriends.com',
    paymentsUrl: 'https://payments.dropfriends.com/',
    webAppUrl: 'https://app.dropfriends.com/',

    appSchema: 'dropfriends://',

    dOSpacesAccess: 'N5GTMGL4XX74HCMI3SQT',
    dOSpacesSecret: '1NOTCbSzSX16fK0xDDqXy60DVv3NsX98f/ZW2o/3CVk',
    dOSpacesBucketName: 'dropfriends',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',

    cloudinary: '375424767218447',
    cloudinarySecret: 'O_lOCnkiFrBulXdmaLb3KLR5gpA',

    hereId: 'eRuKlyokJxvKA5vJUkcv',
    hereCode: 'yESlb_MreW4z1Vwnwqhafg',
  },
};

const getEnvVars = (env = process.env.GATSBY_APP_ENV) => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (env === 'development') {
    return ENV.dev;
  } else if (env === 'staging') {
    return ENV.staging;
  }
  return ENV.prod;
};

export default getEnvVars;
