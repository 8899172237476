import React, { createContext, useState, useContext } from 'react';

const SpinnerContext = createContext(null);

export const SpinnerContextProvider = ({ children }) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const toggleSpinner = newVisibility => {
    setSpinnerVisible(newVisibility);
  };

  return (
    <SpinnerContext.Provider value={{ spinnerVisible, toggleSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  const spinner = useContext(SpinnerContext);

  return spinner;
};

export default SpinnerContext;
