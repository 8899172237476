import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';

import getEnvVars from './environment';

const { apiUrl } = getEnvVars();

export const client = new ApolloClient({
  uri: apiUrl,
  fetch,
});
