import React, { useContext } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

import SpinnerContext from '../contexts/SpinnerContext';

export const GlobalSpinner = () => {
  const { spinnerVisible } = useContext(SpinnerContext);

  return (
    <Flex
      opacity={spinnerVisible ? 1 : 0}
      zIndex={spinnerVisible ? 9999 : -1}
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      backgroundColor="rgba(255,255,255,0.7)">
      <Spinner size="xl" />
    </Flex>
  );
};
