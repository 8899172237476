/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import 'typeface-inter';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './src/client';
import { GlobalSpinner } from './src/components/GlobalSpinner';
import { SpinnerContextProvider } from './src/contexts/SpinnerContext';
import './src/index.css';

export const wrapRootElement = ({ element }) => (
  <SpinnerContextProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
    <GlobalSpinner />
  </SpinnerContextProvider>
);
